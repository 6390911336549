export function debounce(func: any, delay: any) {
  let timerId: any;

  return function (...args: any) {
    clearTimeout(timerId);

    timerId = setTimeout(() => {
      func.apply(null, args);
    }, delay);
  };
}

export function getFirebaseErrorText(error: any) {
  switch (error.code) {
    case "auth/invalid-email":
      return { type: "email", msg: "Email is invalid." };
    case "auth/email-already-in-use":
      return { type: "email", msg: "Email already in use." };
    case "auth/user-not-found":
      return { type: "email", msg: "User is not found." };
    case "auth/missing-password":
      return { type: "password", msg: "Password is required." };
    case "auth/weak-password":
      return { type: "password", msg: "Password is weak." };
    case "auth/wrong-password":
      return { type: "password", msg: "Password is wrong." };
    default:
      return { type: null, msg: error.code };
  }
}

export function getUTC() {
  const currentDate = new Date();
  return currentDate.getTime();
}

export function extractToken() {
  if (window.location.href.includes('add-group')) {
    return window?.location?.href?.split?.('/')?.pop?.() || '';
  }

  return '';
}

export function pathHelper(user?: any) {
  return user ? '/' : '/login';
}
