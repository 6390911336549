import React, { useEffect, useRef, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Login from "./pages/Login";
// import SignUp from "./Signup";
import "./App.css";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./base";
import { debounce, extractToken } from "./utils";
import Loader from "./components/Loader";
import PageNotFound from "./PageNotFound";

export const AuthContext = React.createContext({ currentUser: null, token: '' });

export default function App() {
  const token = useRef(extractToken()).current;
  const [currentUser, setCurrentUser]: any = useState(null);
  const [loading, setLoading]: any = useState(true);
  const debounceSetLoading = debounce(setLoading, 300);

  useEffect(() => {
    return onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      debounceSetLoading(false);
    });
  }, [currentUser, debounceSetLoading]);

  if (loading) {
    return <Loader />;
  }
  console.log(token)
  return (
    <AuthContext.Provider value={{ currentUser, token }}>
      <div className="app">
        <BrowserRouter>
          <Routes>
            <Route path="/" element={currentUser ? <Home /> : <Login />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Login />} />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </BrowserRouter>
      </div>
    </AuthContext.Provider>
  );
}
