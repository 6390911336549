import React, { useState, useContext, useEffect, useRef } from "react";
import { db } from "../base";
import "./Home.css";
import { AuthContext } from "../App";
import { debounce, getUTC } from "../utils";
import { collection, addDoc, getDoc, doc, updateDoc } from "firebase/firestore";
import classNames from "classnames";
import SlideMenu from "../components/SlideMenu";

export default function Home() {
  const { currentUser, token }: { currentUser: { uid: string } | null, token: string } =
    useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const debounceSetLoading = debounce(setLoading, 300);
  const refIsAdded = useRef(false);
  const refIsAddedError = useRef(false);

  async function addNotification() {
    if (!currentUser) {
      return;
    }
    setLoading(true);
    const uid: string = currentUser.uid;
    await addDoc(collection(db, "notifications"), {
      user: uid,
      created: getUTC(),
      device: "browser",
    });
    debounceSetLoading(false);
  }

  const onSuccess = (name: any) => () => {
    if (refIsAdded.current) return;
    refIsAdded.current = true;
    alert(`You have been added to "${name}" group.`);
  }

  const onError = () => {
    if (refIsAddedError.current) return;
    refIsAddedError.current = true;
    alert('Bad link.');
  }

  const handleDeepLink = async (token: string) => {
    if (!currentUser || !token || refIsAdded.current) return;
    const uid: string = (currentUser as any).uid;
    const groupRef = doc(collection(db, 'groups'), token);

    getDoc(groupRef)
      .then(snapshot => {
        const group = snapshot.data() || {};
        let users = group.users || [];
        if (group.owner !== uid && !users.includes(uid)) {
          users.push(uid);

          updateDoc(groupRef, { users: users })
            .then(onSuccess(group.name))
            .catch(onError);
        }
      })
      .catch(onError);
  };

  useEffect(() => {
    handleDeepLink(token);
  }, []);
  console.log(token);
  return (
    <>
      <SlideMenu />
      <div className="container">
        <button
          className="send-notifications"
          onClick={addNotification}
          disabled={loading}
        >
          <div
            className={classNames("bounce-loader", { "not-visible": !loading })}
          >
            <div className="loader"></div>
            <div className="loader"></div>
            <div className="loader"></div>
          </div>
          <span className={classNames({ "not-visible": loading })}>
            Send notifications
          </span>
        </button>
      </div>
    </>
  );
}
