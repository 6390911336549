import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "./base";
import "./PageNotFound.css";
import { ArrowLeftCircle } from "react-feather";
import { onAuthStateChanged } from "firebase/auth";
import { pathHelper } from "./utils";

export default function PageNotFound() {
  const navigate = useNavigate();

  useEffect(() => {
    return onAuthStateChanged(auth, (user) => {
      navigate(pathHelper(user));
    });
  }, []);
  

  return (
    <div className="page-not-found-container">
      <div className="wrapper">
        <h1>Bad link</h1>
        <div onClick={() => navigate("/")} className="navigate-container">
          <ArrowLeftCircle size={20} />
          <span style={{ marginLeft: "20px" }}>Go to main page</span>
        </div>
      </div>
    </div>
  );
}
