import React, { useEffect, useState } from "react";
import { onAuthStateChanged, signInWithEmailAndPassword } from "firebase/auth";
import { Eye, EyeOff } from "react-feather";
import { useNavigate } from "react-router-dom";
import { auth } from "../base";
import classNames from "classnames";
import "./Login.css";
import { getFirebaseErrorText, pathHelper } from "../utils";

export default function Login(): JSX.Element {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const [passwordVisible, setPasswordVisible] = useState(false);

  const login = (e?: any) => {
    e?.preventDefault?.();

    signInWithEmailAndPassword(auth, email, password).catch((error) => {
      setEmailError("");
      setPasswordError("");
      error = getFirebaseErrorText(error);
      if (error.type === "password") {
        setPasswordError(error.msg);
      } else {
        setEmailError(error.msg);
      }
    });
    navigate("/");
  };

  const handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      login();
    }
  };

  useEffect(() => {
    return onAuthStateChanged(auth, (user) => {
      if (user) {
        navigate('/');
      }
    });
  }, []);
  
  return (
    <div className="background-container">
      <div className="login-container">
        <div className="login-form">
          <img
            src={require("../assets/logo-full.png")}
            alt="logo"
            className="logo"
          />
          <div className="input-container">
            {emailError && <span className="error-helper">{emailError}</span>}
            <input
              type="text"
              value={email}
              className={classNames({ "has-val": email }, "input")}
              onChange={(e) => setEmail(e.target.value)}
              onKeyDown={handleKeyDown}
            />
            <span className="input-focus" data-placeholder="Email"></span>
          </div>
          <div className="input-container">
            {passwordError && (
              <span className="error-helper">{passwordError}</span>
            )}
            <span
              className="show-password-btn"
              onClick={() => setPasswordVisible((value) => !value)}
            >
              {passwordVisible ? <Eye size={15} /> : <EyeOff size={15} />}
            </span>

            <input
              value={password}
              className={classNames({ "has-val": password }, "input")}
              type={passwordVisible ? "text" : "password"}
              onChange={(e) => setPassword(e.target.value)}
              onKeyDown={handleKeyDown}
            />
            <span className="input-focus" data-placeholder="Password"></span>
          </div>
          <div className="container-login-form-btn">
            <div className="login-container-form-btn">
              <div className="login-form-bgbtn"></div>
              <button className="login-form-btn" onClick={login}>
                LOG IN
              </button>
            </div>
          </div>
          <div className="change-login-page">
            <span className="text-help">
              Don’t have an account? Go to mobile app
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
