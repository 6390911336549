import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAElJvg1eXhkDT4glgaG_9YzwcNcOHEa7w",
  authDomain: "scalert-fox.firebaseapp.com",
  projectId: "scalert-fox",
  storageBucket: "scalert-fox.appspot.com",
  messagingSenderId: "499598136579",
  appId: "1:499598136579:web:7c04c5dc26483332e60536",
  measurementId: "G-232F4EV1K8",
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth();
export const db = getFirestore(app);
export default app;
